import React,{useState,useEffect } from 'react';
import {useNavigate} from "react-router-dom";
import AdminHeader from '../layout/AdminHeader';
import AdminSidebar from '../layout/AdminSidebar';
import AdminFooter from '../layout/AdminFooter';
import Candidates from "../component/Candidates";
const Application = () => {

    const [heading, setHeading] = useState();
	
    const headingTitle = (newHeading) => {
      setHeading(newHeading);
    }
    var user_data = localStorage.getItem("user_data");
    var user_data_json = JSON.parse(user_data);
    const navigate = useNavigate();

    useEffect(() => {
          
    
        

        (async () => {


            if(!['1','2','3'].includes(user_data_json.role_id))
            {
              navigate('/user/login');
               return;
            }

        })();
    
    }, []);
  return (
    
        <div className="adminview pipeline-view wrapper">
          <AdminHeader />
          <AdminSidebar />
          
          <div className="content-wrapper px-3 pt-1 pb-3">
            <div className="content-header">
                <div className="row">
                    <div className="col-sm-6 pl-0">
                        <h1 className="m-0">{heading}</h1>
                    </div>
                    <div className="col-sm-6 pr-0">
                        <div className="float-right">
                           
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
 


<Candidates  headingTitle={headingTitle}></Candidates>


                         
                        </div>
                    </div>
                </div>
        </div>

        <AdminFooter />
       </div>
      
    );
};



export default Application;
