import React,{useState} from 'react';
 
import { useForm } from 'react-hook-form';
import {NavLink,useNavigate} from "react-router-dom";
import common from '../services/common';


const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors }
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState();
  const onSubmit = async (data) => {

    setLoading(true); 

    

   var login=   await common.login(data);  

  if(login?.response?.status==401)
  {
     
    setLoginError('Invalid email address or password.');
    setLoading(false);
  }
  else if(login.data.status != 1)
  {
    setLoginError('You account is disabled. Please contact admin.');
    setLoading(false);
  }
  else
  {
    
    setLoading(false);

     

    
    localStorage.setItem('user_data',JSON.stringify(login.data));


    
    
    if(login.data.role_id==4)
    {
      navigate("/user/dashboard");
    }
    else
    {
      navigate("/admin/dashboard");
    }
   


 
 
  }
       
  }
  return (
    
        <div className="login-page">

<div className="login-box">
  <div className="login-logo"> 
    <img src="../Poss logo-01.png" style={{maxWidth: 290}} alt="Logo Large" className="brand-image-xs" />
</div>
  <div className="card">
    <div className="card-body login-card-body">
      <h5 className="login-box-msg"><b>Sign In</b></h5>
      <form onSubmit={handleSubmit(onSubmit)}>

      {loginError && <p className='text-danger'> {loginError} </p>}
        <div className="input-group mb-3">
          <input type="text" className="form-control" placeholder="Email"    {...register("email", {
                                        required: "Please enter email address.",
                                        validate: {
                                          maxLength: (v) =>
                                            v.length <= 50 || "The email should have at most 50 characters.",
                                          matchPattern: (v) =>
                                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                            "Please enter a valid email address.",
                                        },
                                      })} />
                                      
          <div className="input-group-append">
            <div className="input-group-text"> <span className="fas fa-envelope"></span> </div>
          </div>
       
        </div>
        {errors.email?.message && (
     <p className='text-danger'>{errors.email.message}</p>
  )}
        <div className="input-group mb-2">
          <input type="password" className="form-control" placeholder="Password"   {...register("password", { required: "Please enter your password."})}/>
          <div className="input-group-append">
            <div className="input-group-text"> <span className="fas fa-lock"></span> </div>
          </div>
        </div>
        {errors.password?.message && (
     <p className='text-danger'>{errors.password.message}</p>
  )}
        <div className="row">
          <div className="col-12">
            <div className="icheck-primary">
              <input className="mr-1" type="checkbox" id="remember" />
              <label htmlFor="remember"> Remember Me </label>
            </div>
          </div>
          <div className="col-12 mt-1 mb-2">           


            <button type="submit" className="btn btn-primary btn-lg btn-block">Sign In {loading ?<span className="spinner-grow spinner-grow-sm"></span>:''}</button>
          </div>
        </div>
      </form>
      <div className="mb-1 mt-2 text-center"> 
          <p className="mb-1"><NavLink to="/user/forgot-password">Forgot Your Password?</NavLink></p>
          <p className="mb-1"><b>OR</b></p>
          <p className="mb-1"><NavLink to="/">Register Now</NavLink></p>
      </div>
    </div>
  </div>
</div>
          
       </div>
      
    );
};

export default Login;
