import { debounce } from "lodash"
import React, { useEffect, useRef, useState } from "react"
import { useNavigate, Link,NavLink } from "react-router-dom";
import { useAppState } from "../hooks/state";
import Paginator from "./Paginator"
import Config from "../config/config";
import axios from "axios";
import common from '../services/common';
import LoadingIcon from '../component/LoadingIcon';
const SORT_ASC = "asc"
const SORT_DESC = "desc"

const PortalPositionData = () => {
    
    const navigate = useNavigate();
    var columns = {};

    // columns['srno'] = "Sr.No.";
    columns['name'] = "Name";
    columns['status'] = "";
 


    const [data, setData] = useState([])
    const [perPage, setPerPage] = useState(50)
    const [sortColumn, setSortColumn] = useState(columns[0])
    const [sortOrder, setSortOrder] = useState("asc")
    const [search, setSearch] = useState("")
    const [pagination, setPagination] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
   
    
    const [loading, setLoading] = useState(true)

    const handleSort = (column) => {
        if (column === sortColumn) {
            sortOrder === SORT_ASC ? setSortOrder(SORT_DESC) : setSortOrder(SORT_ASC)
        } else {
            setSortColumn(column)
            setSortOrder(SORT_ASC)
        }
    }

    const handleSearch = useRef(
        debounce((query) => {
            setSearch(query)
            setCurrentPage(1)
            setSortOrder(SORT_ASC)
            setSortColumn(columns[0])
        }, 500)
    ).current

    const handlePerPage = (perPage) => {
        setCurrentPage(1)
        setPerPage(perPage)
    }

   

    useEffect(() => {

    
        clearInterval(Config.intervalId);

        const fetchData = async () => {

          ///  console.log(data);

            if(data==null)
            {
                setLoading(true)
            }
        


            const params = {
                search,
                sort_field: sortColumn,
                sort_order: sortOrder,
                per_page: perPage,
                page: currentPage,
            }

           var pagedata = {}
          

           var getportaluserlistdata = await common.getportalpositionlist(params);

           
           if(getportaluserlistdata?.data?.status==400)
           {
             navigate('/user/logout');
             return;
           }

     
        if(getportaluserlistdata?.data?.status==202)
           {

        

        pagedata ["current_page"] = getportaluserlistdata?.data?.data?.current_page;
        pagedata ["from"] = getportaluserlistdata?.data?.data?.from;
        pagedata ["last_page"] = getportaluserlistdata?.data?.data?.last_page;
        pagedata ["per_page"] = getportaluserlistdata?.data?.data?.per_page;
        pagedata ["to"] = getportaluserlistdata?.data?.data?.to;
        pagedata ["total"] = getportaluserlistdata?.data?.data?.total;

        setData(getportaluserlistdata?.data?.data?.data)
        setPagination(pagedata)
        setTimeout(() => {
            setLoading(false)
        }, 300)



           }else
           {

           }
               





            
           
        }

        fetchData()


        Config.intervalId = setInterval(() => {
            fetchData(); // Fetch data every 2 minutes
          }, Config.auto_refresh_time);
      
         
          

          
    }, [perPage, sortColumn, sortOrder, search, currentPage])

    return (
        <div>
            {/* Search per page starts  */}
            <div className="row mb-3">
            <div className="col-md-9">
                    <div className="input-group">
                        <label className="mt-2 me-2">Per page</label>
                        <select className="form-select" value={perPage} onChange={(e) => handlePerPage(e.target.value)}>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="input-group">
                        <input
                            className="form-control"
                            placeholder="Search..."
                            type="search"
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </div>
                </div>
                
            </div> 
            {/* Search per page ends  */}
            
                <table className="table table-striped dataTable dtr-inline data-table">
                    <thead >
                        <tr>
                        {Object.keys(columns).map((key) => {
                                return (
                                    <th key={key} onClick={(e) => handleSort(key)}>
                                       
                                       

                                        {(() => {
        if (columns[key]=='created_at') {
          return      <i className="fa-solid fa-clock"></i>
          
        } else if (columns[key]=='updated_at') {
          return           <i className="fa-solid fa-bolt"></i>
          
        } else {
          return (
            columns[key]
          )
        }
      })()}  
                                        {key === sortColumn ? (
                                            <span>
                                                {sortOrder === SORT_ASC ? (
                                                    <i className="ms-1 fa fa-arrow-up" aria-hidden="true"></i>
                                                ) : (
                                                    <i className="ms-1 fa fa-arrow-down" aria-hidden="true"></i>
                                                )}
                                            </span>
                                        ) : null}
                                    </th>
                                )
                            })}
                        </tr>



                    </thead>
                    <tbody>
                        {data?.length === 0 ? (
                            <tr>
                                <td colSpan={Object.keys(columns).length}>No items found</td> 
                            </tr>
                        ) : (
                            ""
                        )}

                        {!loading ? (
                            data.map((d, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{d.name}</td>
                                        <td><NavLink to={'/admin/edit-position/'+d.id}><i className="fas fa-edit"></i></NavLink></td>
                                    </tr>
                                )
                            })
                        ) : (
                            <tr>
                                <td colSpan={Object.keys(columns).length + 1}>
                                <LoadingIcon />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
             
            {data?.length > 0 && !loading ? (
                <div className="mt-2">
                    <Paginator
                        pagination={pagination}
                        pageChanged={(page) => setCurrentPage(page)}
                        totalItems={data.length}
                    />
                </div>
            ) : null}
        </div>
    )
}

export default PortalPositionData