import { NavLink, useNavigate, useLocation, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";

import AdminHeader from '../layout/AdminHeader';
import AdminSidebar from '../layout/AdminSidebar';
import AdminFooter from '../layout/AdminFooter';
import EmployeeData from "../component/EmployeeData";

const Employees = () => {

    var user_data = localStorage.getItem("user_data");
    var user_data_json = JSON.parse(user_data);
    const navigate = useNavigate();


    useEffect(() => {

        if(!['1','2','3'].includes(user_data_json.role_id))
        {
          navigate('/user/login');
           return;
        }
    
    }, [])
    
  return (
    
        <div className="adminview pipeline-view wrapper">
          <AdminHeader />
          <AdminSidebar />
          
          <div className="content-wrapper px-3 pt-1 pb-3">
            <div className="content-header">
                <div className="row">
                    <div className="col-sm-6 pl-0">
                        <h1 className="m-0">Employees</h1>
                    </div>
                    <div className="col-sm-6 pr-0">
                        <div className="float-right">
                             
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                    <div className="card-body">
                        <div className="table-responsive">
                        <EmployeeData ></EmployeeData>
                        </div>
                    </div>
                </div>
        </div>

        <AdminFooter />
       </div>
      
    );
};

export default Employees;
