import React,{ useRef, useState, useEffect } from 'react';
import { useForm,Controller  } from "react-hook-form";
import { useNavigate, NavLink } from "react-router-dom";
import { useAppState } from "../hooks/state";
import SignatureCanvas from 'react-signature-canvas';
import "react-datepicker/dist/react-datepicker.css";
import moment from"moment";
import common from '../services/common';
import { Store } from 'react-notifications-component';
const EmploymentApplicationStep6 = () => {

    const today = new Date();
    const year = today.getFullYear();
    const sigRef = useRef();
    const [signature, setSignature] = useState(null);
    const formatIntoPng = () => {


        if (sigRef.current) {
            setSignature(sigRef.current.toDataURL());
            return   sigRef.current.getTrimmedCanvas().toDataURL("image/png");
          }
    

    }
    const clearSignature = () => {

        reset({
            signature: ""
          })
      sigRef.current.clear();
      setSignature(null);
    }
    
    const navigate = useNavigate();
    const [state, setState] = useAppState();
    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors }
      } = useForm({
        defaultValues: state,
      });
      const [dynamicField, setDynamicField] = useAppState();
      const onSubmit =async (data) => {
        console.log(data);

        
        setState({ ...state, ...data });

        const finaldata = new FormData() 
        finaldata.append('first_name', state.first_name);
        finaldata.append('last_name', state.last_name);
        finaldata.append('email', state.email);
        finaldata.append('address', state.address);
        finaldata.append('address_2', state.address_2);
        finaldata.append('city', state.city);        
        finaldata.append('state', state.state);
        finaldata.append('zipcode', state.zipcode);
        finaldata.append('phone', state.phone);
        finaldata.append('legally_entitled_us', state.legally_entitled_us);





        finaldata.append('employment_type_id', state.employment_type_id);
        finaldata.append('position_id', state.position_id);
        finaldata.append('desired_type', state.desired_type);
        finaldata.append('area_type', state.area_type);
        finaldata.append('perform_the_essential_functions', state.perform_the_essential_functions);
        finaldata.append('date_available', moment(state.date_available).format("YYYY-MM-DD"));

        finaldata.append('highest_level_education', state.highest_level_education);
        finaldata.append('highest_level_education_other', state.highest_level_education_other);


        finaldata.append('crime', state.crime);
        finaldata.append('crime_yes', state.crime_yes);


        finaldata.append('professional_references', JSON.stringify(state.professional_references));



        finaldata.append('name_of_applicant', data.name_of_applicant);
        finaldata.append('date',  moment().format("YYYY-MM-DD"));
        finaldata.append('signature', data.signature);
        finaldata.append('resume', data.resume[0]);
        finaldata.append('availability', data.availability);
        

        var registerapi = await common.register(finaldata);

        if (registerapi?.data?.status == 202) {
            setState([]);
            navigate('/',{state:{success:'1'}});
        }else

        {
            Store.addNotification({
                title: "Error!",
                message: registerapi?.data?.message,
                type: "danger",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });

        }        
    }

    useEffect(() => {
        
 
        reset({
            signature: ""
          });

      if (Object.keys(dynamicField).length == 0) {
              
       }
      
      
      
          }, []);

    
  return (
        <div className="web-version">
            
            <div className="header-main">
              <div className="header-top">
                  <div className="container">
                  <nav className="navbar navbar-expand-sm navbar-dark">
                      <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/user/login">Login</NavLink>
                        </li>
                        <li className="nav-item">
                            <span className="nav-link"><small>|</small></span>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link pr-0" to="/">Register</NavLink>
                        </li>
                      </ul>
                      </nav>
                  </div>
              </div>
              <div className="header-middle">
                  <div className="container">
                  <div className="d-flex align-items-center">
                    <div className="mr-auto">
                      <NavLink className="brand-logo-link" to="/">
                        <img src="../Poss logo-01.png?v=1" alt="Logo" className="brand-logo" />
                      </NavLink>
                    </div>
                    <div className="ml-auto">
                        <div className="web-header-title">EMPLOYMENT APPLICATION</div>
                    </div>
                    </div>
                  </div>
              </div>
            </div>

            <div className="web-page-content">
                <div className="container">
                    <div className="web-page-inner">

                    <form onSubmit={handleSubmit(onSubmit)}>

                <div className="steps-content step-content-2">
                    <ul className="progressbar">  
                        <li className="steps step-1 active"><span>1</span><strong>General <br/>Information</strong></li>  
                        <li className="steps step-2 active"><span>2</span><strong>Position </strong></li>  
                        <li className="steps step-3 active"><span>3</span><strong>Education and <br/>Training</strong></li>  
                        <li className="steps step-4 active"><span>4</span><strong>Background</strong></li>
                        <li className="steps step-5 active"><span>5</span><strong>Professional <br/>References</strong></li> 
                        <li className="steps step-6 active"><span>6</span><strong>Applicant's Certification <br/>Agreement</strong></li> 
                    </ul>  
                    
                    
                    <div className="card card-primary">
                    <div className="card-header with-border clearfix">
                        <div className="card-title">Applicant's Certification Agreement</div>
                    </div>
                    <div className="card-body">
                    <p>1. I understand that I may submit a copy of my résumé or curriculum vitae (CV) and that by submitting a copy of my résumé/CV I understand that it will be used only as supporting and additional background information. A résumé/CV is not an authorized substitute for a completed employee application</p>
                    <p>2. I understand that if I should choose to complete only a portion of the required employment application that the information submitted may not be enough information from which to base any determination on, and, as a result, my application may not receive full consideration for employment</p>
                    <p>3. I authorize the investigation of all statements contained in this application and release from all liability any persons or employers supplying such information. I also release Progressive Option Support Services from all liability that may result from making background investigations.</p>
                    <p>4. I certify that the facts and information set forth in this application are true and complete to the best of my knowledge. I understand that any falsification, misrepresentation, or omission of facts on this application (or any required documents) will be cause for denial of employment or immediate termination of employment, regardless of when or how discovered.</p>
                    <p>5. I agree, if I am offered and accept a position, to conform to all existing and future workplace rules, regulations, policies, and procedures of Progressive Option Support Services.</p>
                    <p>6. I understand and agree that Progressive Option Support Services reserves the right to change any wage and hours of work, in its sole discretion, at any time as deemed necessary.</p>
                    <p>7. I understand the employment relationship will be At Will, meaning that either party can end the employment relationship at any time, and for any reason, or no reason with written notice.</p>
                    <p>8. I understand that I must submit to fingerprinting or background checks and/or medical testing as part of the process to determine my fitness for employment and hereby agree to submit to such screening. I authorize all persons, agencies, or other entities to release any information concerning my background or test results and hereby release from all liability any persons, agencies, or other entities supplying such information. I also release Progressive Option Support Services from all liability that may result from making such investigations. I understand that I must participate in fingerprinting/background checks which includes credit, criminal history, child abuse, sexual offender registry, and motor vehicle record checks prior to beginning employment.</p>
                    <p>9. I understand that Progressive Option Support Services reserves the right to perform random drug testing.</p>
                    <p>10. I understand that any employment offer is contingent upon my providing proof of identity and eligibility to work within the United States to conform with the provisions of the Immigration Reform and Control Act of 1986.</p>
                    <p>11. I understand that all programs developed as part of my job responsibilities and all materials that I am entitled to receiving as part of my employment are the property of Progressive Option Support Services and that I will not try to copy, use, publish, or replicate a program or any materials for personal use, business ventures, or with other businesses. I understand that if this occurs legal action will ensue against me for violating this term of my employment.</p>
                    <p>I have read and reviewed the information contained in this employment application, as well as the above-mentioned statements of agreement. By signing this employment application, I certify that I understand all of the information requested and that I have provided information that is truthful, complete, and accurate.</p>

                    <div className="row">

                    <div className="col-md-8">

                    <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Name of Applicant <span className="required">*</span></label>
                                    <input className="form-control" name="name_of_applicant"   type="text"  {...register('name_of_applicant', { required: "Please enter name of applicant." })}/>
                                    
                                    {errors.name_of_applicant?.message && (
     <p className='text-danger'>{errors.name_of_applicant.message}</p>
  )}
                                </div>
                            </div>


                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Resume/CV <span className="required">*</span></label>
                                    <input className="form-control" type="file" name="resume"  accept='.pdf,.doc,.docx' {...register('resume',{ required: "Please select your resume.",   validate: {
                                                                            fileupload: (files) => {
                                                                                if (files.length != 0) {
                                                                                    if (files[0]?.size > 10000000) {
                                                                                        return "Allow only max 10MB.";
                                                                                    }
                                                                                    else if (!['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(files[0]?.type)) {
                                                                                        return "Allow only pdf, doc, docx format.";
                                                                                    }
                                                                                }

                                                                            }
                                                                        } })}  />
                               
                                    {errors.resume?.message && (
     <p className='text-danger'>{errors.resume.message}</p>
  )}

                               
                                </div>
                            </div>
  </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Availability <span className="required">*</span></label>
                                    
                                         
                                        {dynamicField.availabilities_field && dynamicField.availabilities_field.map((av, key) => {
    return   <div className="form-check" key={key}>
    <input type="checkbox" className="form-check-input" value={av.id}  {...register('availability',{ required: "Please select availability." })}/>
    <label className="form-check-label">{av.name}</label>
</div>;
})} 
 

                                  

                                    {errors.availability?.message && (
     <p className='text-danger'>{errors.availability.message}</p>
  )}
                                </div>
                            </div>                         
                            </div>  

                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Signature of Applicant<span className="required">*</span></label>
                                    

                                
     <Controller
          name="signature"
          control={control}
          rules={{ required: "Please write a signature." }}
          render={({ field }) => (
            <SignatureCanvas
              ref={sigRef}
              onEnd={() => field.onChange(formatIntoPng())}
              penColor="black"
              canvasProps={{
                width: 315,
                height: 200,
                style: { border: "1px solid black" },
              }}
            />
          )}
        />
    <button onClick={clearSignature}>Clear</button> 
                                </div>
                                {errors.signature?.message && (
     <p className='text-danger'>{errors.signature.message}</p>
  )}

                            </div>
                        </div>
                    <p>Progressive Option Support Services is an Equal Employment Opportunity employer, and we do not discriminate in our hiring or employment practices. All qualified applicants will receive consideration without regard to race, color, creed, religion, national origin, age, disability, sex, or any other characteristic protected by State or Federal law.</p>
                    <div className="row">
                        </div>
                    </div>
                    </div>
                  <div className="card-footer-step text-center">
                        <NavLink className="btn btn-primary" to="/employment/professional"><i className="right fas fa-angle-left"></i> Previous</NavLink>

                        <button type="submit" className="btn btn-primary">Finish<i className="right fas fa-angle-right"></i></button>
                  
                  </div>
                </div>

                </form>
                </div>
              </div>
            </div>


            <div className="footer-main bg-dark">
                <div className="container">
                    <p className="text-center">Copyrights © {year} All rights reserved. POSS</p>
                </div>
            </div>

        </div>
    );
};

export default EmploymentApplicationStep6;
