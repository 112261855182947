import React, { useEffect } from 'react';
import { NavLink,useNavigate } from "react-router-dom";
import PortalPositionData from "../component/PortalPositionData";
import AdminHeader from '../layout/AdminHeader';
import AdminSidebar from '../layout/AdminSidebar';
import AdminFooter from '../layout/AdminFooter';


const PositionPipeline = () => {

    var user_data = localStorage.getItem("user_data");
  var user_data_json = JSON.parse(user_data);
  const navigate = useNavigate();


  useEffect(() => {
    (async () => {
        if(!['1'].includes(user_data_json.role_id))
        {
          navigate('/user/login');
           return;
        }



    })();

}, []);

  return (
    
        <div className="adminview pipeline-view wrapper">
          <AdminHeader />
          <AdminSidebar />
          
          <div className="content-wrapper px-3 pt-1 pb-3">
            <div className="content-header">
                <div className="row">
                    <div className="col-sm-6 pl-0">
                        <h1 className="m-0">Position List</h1>
                    </div>
                    <div className="col-sm-6 pr-0">
                        <div className="float-right">
                          <NavLink className="btn btn-link" to="/admin/add-position"><i className="fas fa-plus-square"></i> Add new</NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                    <div className="card-body">
                        <div className="table-responsive" style={{'overflow': "hidden"}}>

                        <PortalPositionData
                    fetchUrl="api/get-position"
                    columns={["Name", "Status"]}
                ></PortalPositionData>


                        </div>
                    </div>
                </div>
        </div>

        <AdminFooter />
       </div>
      
    );
};

export default PositionPipeline;
