import React,{useState,useEffect} from 'react';
import {NavLink,useNavigate} from "react-router-dom";
import { useForm ,Controller} from 'react-hook-form';
import AdminHeader from '../layout/AdminHeader';
import AdminSidebar from '../layout/AdminSidebar';
import AdminFooter from '../layout/AdminFooter';
import Select from 'react-select';
import common from '../services/common' 
import { Store } from 'react-notifications-component';
import Config from '../config/config';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddPayIncrease = () => {

    var user_data = localStorage.getItem("user_data");
    var user_data_json = JSON.parse(user_data);
    const [dropdownData, setDropdownData] = useState(null);

    const [userrole, setUserrole] = useState(null);
    
    const [loading, setLoading] = useState(false);

    const [dropDownOpt, setdropDownOpt] = useState([]);
   
    
    const [loginError, setLoginError] = useState();
    const [apiEdit, setApiEdit] = useState([])
    const navigate = useNavigate();

    const formatCurrency = (amount) => {
        const formattedAmount = new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).format(amount);
    
        // Remove .00 if present
        return formattedAmount.replace(/\.00$/, '');
    };
    const handleCandidateDetailChange = async (selectedOption) => {
        const selectedCandidateId = selectedOption.value;
        var  edituser= await common.getcandidatepayRcd(selectedCandidateId);  

        var dropdownvalue= await common.getdropdown(null);     
        var getemployeelisttdata = await common.getAllEmployee();
        const dropdownemployeelisttdata = getemployeelisttdata.data.data.data.map((response) => ({
            "value" : response.id,
            "label" : response.name
          }))
          setDropdownData(dropdownemployeelisttdata);

    
        const dropDownValuedata = dropdownvalue.data.data.position_field_pay.map((response) => ({
            "value" : response.id,
            "label" : response.name
        }))

          setdropDownOpt(dropDownValuedata);
              setTimeout(() => {

                console.log(edituser.data);
                var dropDownValuedataedit =  dropDownValuedata.filter(function(po) {
                    const position_ids = edituser.data.data.employment_type.split(',').map(str => {
                        return parseInt(str, 10);
                      });
                    return  position_ids.includes(po.value);
                  });

                var dropDownValuedatacandidate = dropdownemployeelisttdata.filter(function(po) {
                    const selectedPositionIds = Array.isArray(edituser.data.data.candidate_detail_id)
                        ? edituser.data.data.candidate_detail_id.map(str => parseInt(str, 10))
                        : [parseInt(edituser.data.data.candidate_detail_id, 10)]; // Convert to an array if it's a single value
                    
                    return selectedPositionIds.includes(po.value);
                });
                  edituser.data.data.employment_type=dropDownValuedataedit;
                  edituser.data.data.starting_rate = formatCurrency(edituser.data.data.starting_rate);
                  edituser.data.data.new_rate = formatCurrency(edituser.data.data.new_rate);
                  
                  edituser.data.data.candidate_detail_id=dropDownValuedatacandidate;
                if (edituser?.data?.data?.start_date && edituser.data.data.start_date != "" && edituser.data.data.start_date != "0000-00-00") {
                    edituser.data.data.start_date = moment(edituser.data.data.start_date).toDate();
                }
                if (edituser?.data?.data?.increase_date && edituser.data.data.increase_date != "" && edituser.data.data.increase_date != "0000-00-00") {
                    edituser.data.data.increase_date = moment(edituser.data.data.increase_date).toDate();
                }
               
                setApiEdit(edituser.data.data);
               reset(edituser.data.data);
 
                
               setUserrole(edituser.data.data.role_id)


              }, 0);
        console.log("Selected Candidate ID:", selectedCandidateId);
    };

    const {
        control,
        register,
        getValues,
        handleSubmit,
        setValue,
        reset,
        formState: { errors }
      } = useForm({
        increase_date: null,
        start_date: null,
        defaultValues: {apiEdit}
      });

  

      const onSubmit = async (data) => {
        setLoading(true); 
        data.candidate_detail_id = data.candidate_detail_id[0].value;
        let employment_type_m = data.employment_type.map(a => a.value);
        data.employment_type = employment_type_m;
        data.increase_date = moment(data.increase_date).format("YYYY-MM-DD");
        data.start_date = moment(data.start_date).format("YYYY-MM-DD");
        var AddPayIncreaseapi=   await common.AddorupdatePayIncrease(data);  
        if(AddPayIncreaseapi?.data?.status==400)
        {
          navigate('/user/logout');
          return;
        }
       if(AddPayIncreaseapi?.data?.status==202)
       {
        Store.addNotification({
            title: "Success!",
            message: AddPayIncreaseapi?.data?.message,
            type: "success",           
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          navigate('/admin/pay-increase',{state:{success:'1'}});
         setLoading(false);
       }
       else
       {
        Store.addNotification({
            title: "Error!",
            message: AddPayIncreaseapi?.data?.message,
            type: "danger",            
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
         setLoading(false);
      }
    }
  


      useEffect(() => {
       
        clearInterval(Config.intervalId);
        (async () => {


            if(!['1'].includes(user_data_json.role_id))
            {
              navigate('/user/login');
               return;
            }


            var dropdownvalue= await common.getdropdown(null);   
            
            var getemployeelisttdata = await common.getAllEmployee();
            const dropdownemployeelisttdata = getemployeelisttdata.data.data.data.map((response) => ({
                "value" : response.id,
                "label" : response.name
              }))
              setDropdownData(dropdownemployeelisttdata);
            const dropDownValuedata = dropdownvalue.data.data.position_field_pay.map((response) => ({
                "value" : response.id,
                "label" : response.name
              }))

              setdropDownOpt(dropDownValuedata);

           
            
          })();
    
      }, []);
  return (
        
        <div className="adminview wrapper">
             <style>
                {`
                    .css-12a83d4-MultiValueRemove {
                        display: none;
                    }
                    .css-v7duua{
                        display: none;
                    }
                    .css-1xc3v61-indicatorContainer{
                        display:none;
                    }
                `}
            </style>
          <AdminHeader />
          <AdminSidebar />
          
          <div className="content-wrapper px-3 pt-1 pb-3">
            <div className="content-header">
                <div className="row">
                    <div className="col-sm-6 pl-0">
                        <h1 className="m-0">
                            Add New Pay Increase
                            <small>
                                <NavLink to="/admin/pay-increase"><i className="fas fa-arrow-circle-left"></i> back to Pay Increase list</NavLink>
                            </small>
                        </h1>
                    </div>
                    <div className="col-sm-6 pr-0">
                        <div className="float-right">
                            <button type="submit" className="btn btn-primary"  form="add_user" >
                                <i className="far fa-save"></i> Save {loading ?<span className="spinner-grow spinner-grow-sm"></span>:''}
                            </button>
                         
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="form-horizontal">

                <div className="card card-secondary card-outline" data-card-name="customer-info" id="customer-info">
                    <div className="card-header with-border clearfix">
                        <div className="card-title"><i className="fas fa-info"></i>Pay Increase info</div>
                        <div className="card-tools float-right">
                            <button className="btn btn-tool" data-card-widget="collapse" type="button">
                                <i className="fa toggle-icon fa-minus"></i>
                            </button>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} id="add_user">
                    <input   type="hidden"  {...register("user_id")} value={0}/>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <div className="col-md-3">
                                        <div className="label-wrapper">
                                            <label className="col-form-label">Candidate <span className="required">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                    <Controller
                                        name="candidate_detail_id"
                                        control={control}
                                        rules={{ required: "Please select candidate." }}
                                        render={({ field }) => (
                                            <Select
                                            {...field}
                                            options={dropdownData}
                                            defaultValue={1}
                                            onChange={(selectedOption) => handleCandidateDetailChange(selectedOption)}
                                            />
                                        )}
                                        />
                                        {errors.candidate_detail_id?.message && (
                                        <p className='text-danger'>{errors.candidate_detail_id.message}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">



                            {userrole!=1 && <div className="form-group row">
                                    <div className="col-md-3">
                                        <div className="label-wrapper">
                                            <label className="col-form-label">Employment Type <span className="required">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <Controller
                                            name="employment_type"
                                            control={control}
                                            rules={{ required: "Please select employment type." }}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    options={dropDownOpt.map(option => ({
                                                        ...option,
                                                        isDisabled: true, // Set isDisabled for each option
                                                    }))}
                                                    defaultValue={1}
                                                    isMulti
                                                    isSearchable={false} // Disable search to make it non-editable
                                                    closeMenuOnSelect={false} // Allow multiple selections without closing the menu
                                                    isClearable={false} // Disable the clear indicator
                                                />
                                            )}
                                        />
                                        {errors.employment_type?.message && (
                                            <p className='text-danger'>{errors.employment_type.message}</p>
                                        )}
                                    </div>

                                </div>
                                }
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <div className="col-md-3">
                                        <div className="label-wrapper">
                                            <label className="col-form-label">Start Date <span className="required">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                    <Controller
                                        control={control}
                                        name='start_date'
                                        rules={{ required: "Please select start date" }}
                                        render={({ field, fieldState }) => (
                                            <div>
                                                <DatePicker
                                                    className="form-control text-box single-line datetimepicker-input"
                                                    placeholderText='Select date'
                                                    onChange={(date) => field.onChange(date)}
                                                    selected={field.value}
                                                    maxDate={new Date()}
                                                    dateFormat="MM/dd/yyyy"
                                                />
                                                {fieldState.error && (
                                                    <p className='text-danger'>{fieldState.error.message}</p>
                                                )}
                                            </div>
                                        )}
                                    />

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <div className="col-md-3">
                                        <div className="label-wrapper">
                                            <label className="col-form-label">Start Rate <span className="required">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <input className="form-control text-box single-line" type="text"  {...register("starting_rate", { required: "Please enter start rate." })} />
                                        {errors.starting_rate?.message && (
                                            <p className='text-danger'>{errors.starting_rate.message}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <div className="col-md-3">
                                        <div className="label-wrapper">
                                            <label className="col-form-label">Rate Increase <span className="required">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <input className="form-control text-box single-line" type="text"  {...register("new_rate", { required: "Please enter rate increase." })} />
                                        {errors.new_rate?.message && (
                                            <p className='text-danger'>{errors.new_rate.message}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <div className="col-md-3">
                                        <div className="label-wrapper">
                                            <label className="col-form-label">Increase Date <span className="required">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                    <Controller
                                        control={control}
                                        name='increase_date'
                                        rules={{ required: "Please select increase date" }}
                                        render={({ field, fieldState }) => (
                                            <div>
                                                <DatePicker
                                                    className="form-control text-box single-line datetimepicker-input"
                                                    placeholderText='Select date'
                                                    onChange={(date) => field.onChange(date)}
                                                    selected={field.value}
                                                    // maxDate={new Date()}
                                                    dateFormat="MM/dd/yyyy"
                                                />
                                                {fieldState.error && (
                                                    <p className='text-danger'>{fieldState.error.message}</p>
                                                )}
                                            </div>
                                        )}
                                    />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </form>
                </div>
            </div>

            <div id="delete-confirmation" className="modal fade" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Are you sure?</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                        </div>
                    <div className="form-horizontal">
                        <div className="modal-body">
                            <p>Are you sure you want to delete this Pay Increase?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-danger">Delete</button>
                            <span className="btn btn-default" data-dismiss="modal">Cancel</span>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

        </div>

        <AdminFooter />
       </div>
      
    );
};

export default AddPayIncrease
