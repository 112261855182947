import React,{ useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import 'jquery/src/jquery';
import 'bootstrap/dist/js/bootstrap';
import 'admin-lte/build/js/AdminLTE';
import 'admin-lte/dist/css/adminlte.min.css';
import "datatables.net-bs4"
import './css/theme-custom.css';

import ReactDOM from 'react-dom/client';
import { AppProvider } from "./hooks/state";

import  EmploymentApplicationStep1  from './pages/EmploymentApplicationStep1';
import  EmploymentApplicationStep2  from './pages/EmploymentApplicationStep2';
import  EmploymentApplicationStep3  from './pages/EmploymentApplicationStep3';
import  EmploymentApplicationStep4  from './pages/EmploymentApplicationStep4';
import  EmploymentApplicationStep5  from './pages/EmploymentApplicationStep5';
import  EmploymentApplicationStep6  from './pages/EmploymentApplicationStep6';



import Logout from './pages/Logout';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import PortalUsers from './pages/PortalUsers';
import AddPortalUser from './pages/AddPortalUser';
import EditPortalUser from './pages/EditPortalUser';

import PortalPosition from './pages/PortalPosition';
import AddPosition from './pages/AddPosition';
import EditPosition from './pages/EditPosition';

import PayIncrease from './pages/PayIncrease';
import AddPayIncrease from './pages/AddPayIncrease';
import EditPayIncrease from './pages/EditPayIncrease';


import Location from './pages/Location';
import DirectDeposit from './pages/DirectDeposit';
import AddLocation from './pages/AddLocation';
import EditLocation from './pages/EditLocation';

import Applications from './pages/Applications';
import ApplicationsPipeline from './pages/ApplicationsPipeline';
import PositionStatus from './pages/PositionStatus';
import PositionPipeline from './pages/PositionPipeline';
import ApplicationsList from './pages/ApplicationsList';
import EditApplication from './pages/EditApplication';
import EditApplicationDocuments from './pages/EditApplicationDocuments';
import Employees from './pages/Employees';

import EditProfile from './pages/EditProfile';

import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';









 












//import App from './App';
import reportWebVitals from './reportWebVitals';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
const getBasename = () => `/${process.env.PUBLIC_URL.split('/').pop()}`;

export default function App() {




  


 





  return (
    <BrowserRouter basename={getBasename()}>
        <ReactNotifications />
       <AppProvider>
      <Routes>

            <Route index element={<EmploymentApplicationStep1 />} />            
            <Route path="employment/position" element={<EmploymentApplicationStep2 />} />
            <Route path="employment/education" element={<EmploymentApplicationStep3 />} />
            <Route path="employment/background" element={<EmploymentApplicationStep4 />} />
            <Route path="employment/professional" element={<EmploymentApplicationStep5 />} />
            <Route path="employment/certification" element={<EmploymentApplicationStep6 />} />
            <Route path="user/login" element={<Login />} />
            <Route path="user/forgot-password" element={<ForgotPassword />} />  
            <Route path="user/reset-password" element={<ResetPassword />} />  


            <Route path="admin/dashboard" element={<Dashboard />} />
            <Route path="user/dashboard" element={<EditApplication />} />
            <Route path="user/documents" element={<EditApplicationDocuments />} />


            <Route path="admin/portal-users" element={<PortalUsers />} />
            <Route path="admin/add-portal-user" element={<AddPortalUser />} />
            <Route path="admin/edit-portal-user/:id" element={<EditPortalUser />} />

            <Route path="admin/position" element={<PortalPosition />} />
            <Route path="admin/add-position" element={<AddPosition />} />
            <Route path="admin/edit-position/:id" element={<EditPosition />} />


            <Route path="admin/pay-increase" element={<PayIncrease />} />
            <Route path="admin/add-pay-increase" element={<AddPayIncrease />} />
            <Route path="admin/edit-pay-increase/:id" element={<EditPayIncrease />} />

            <Route path="admin/location" element={<Location />} />
            <Route path="admin/direct-deposit" element={<DirectDeposit />} />
            <Route path="admin/add-location" element={<AddLocation />} />
            <Route path="admin/edit-location/:id" element={<EditLocation />} />


            <Route path="admin/applications" element={<Applications />} />
            <Route path="admin/applications-pipeline/:id/:locationid" element={<ApplicationsPipeline />} />
            <Route path="admin/edit-position-status/:id" element={<PositionStatus />} />
            
            <Route path="admin/position-pipeline" element={<PositionPipeline />} />
            
            <Route path="admin/applications-list/:id/:locationid" element={<ApplicationsList />} />
            <Route path="admin/edit-application/:id" element={<EditApplication />} />
            <Route path="admin/edit-application-documents/:id" element={<EditApplicationDocuments />} />


            <Route path="admin/employees" element={<Employees />} />
            <Route path="admin/edit-profile" element={<EditProfile />} />
            <Route path="user/logout" element={<Logout />} />


            
{/*

Not Use
  */}


        </Routes>
        </AppProvider>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
