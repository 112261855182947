
import { NavLink, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import LoadingIcon from '../component/LoadingIcon';
import Config from "../config/config";
import AdminHeader from '../layout/AdminHeader';
import AdminSidebar from '../layout/AdminSidebar';
import AdminFooter from '../layout/AdminFooter';
import common from '../services/common';
import { Store } from 'react-notifications-component';
const ApplicationsPipeline = () => {
    const [columns, setColumns] = useState({
        "Deactivate": {
            position_name: "Inactive",
            items: [],
        },
       "Active": {
            position_name: "Active",
            items: [],
        },
    });
    const [positionName, setPositionName] = useState('');
    const { id } = useParams();
    const navigate = useNavigate();
  
    useEffect(() => {
      clearInterval(Config.intervalId);
  
      // Fetch data and update columns
      const fetchData = async () => {
        try {
          const getcandidatepiplinedata = await common.getpositionstatus(id);
  
          if (getcandidatepiplinedata?.data?.status === 400) {
            navigate('/user/logout');
            return;
          }
  
          const updatedColumns = {
            "Deactivate": {
                position_name: "Inactive",
                items: [],
            },
           "Active": {
                position_name: "Active",
                items: [],
            },
          };
          
          getcandidatepiplinedata.data.data.forEach((item) => {
            const targetColumn = item.status === 1 ? 'Active' : 'Deactivate';
            updatedColumns[targetColumn].items.push(item);
          });
          
          setColumns(updatedColumns);
          setPositionName(getcandidatepiplinedata?.data?.position_name);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
  
      Config.intervalId = setInterval(() => {
        fetchData(); // Fetch data every 2 minutes
      }, Config.auto_refresh_time);
  
      return () => clearInterval(Config.intervalId);
    }, [id, navigate]);
  
    const onDragEnd = async (result) => {
      if (!result.destination) return;
      const { source, destination } = result;
  
      if (source.droppableId !== destination.droppableId) {
            // Determine the source and destination columns
        const sourceColumn = columns[source.droppableId];
        const destColumn = columns[destination.droppableId];

        // Get the dragged item's ID
        const itemId = result.draggableId;

        // Determine if the item is moving from "Active" to "Deactivate" or vice versa
        const isMovingToActive = destination.droppableId === 'Active';

        // Determine the new status based on the destination column
        const newStatus = isMovingToActive ? 'Active' : 'Deactivate';
        const statusId = newStatus == 'Active' ? 1 : 0;
        var Updatecandidatestatusapi=   await common.updatepositionstatus(itemId, statusId);  
        if(Updatecandidatestatusapi?.data?.status==400)
        {
          navigate('/user/logout');
          return;
        }
  
       
        if(Updatecandidatestatusapi?.data?.status==202)
        {
          Store.addNotification({
             title: "Success!",
             message: Updatecandidatestatusapi?.data?.message,
             type: "success",           
             container: "top-right",
             animationIn: ["animate__animated", "animate__fadeIn"],
             animationOut: ["animate__animated", "animate__fadeOut"],
             dismiss: {
               duration: 3000,
               onScreen: true
             }
           });
           
        
         
        }
        else
        {
          Store.addNotification({
             title: "Error!",
             message: Updatecandidatestatusapi?.data?.message,
             type: "danger",            
             container: "top-right",
             animationIn: ["animate__animated", "animate__fadeIn"],
             animationOut: ["animate__animated", "animate__fadeOut"],
             dismiss: {
               duration: 3000,
               onScreen: true
             }
           });
        }
  
        // Handle the drag-and-drop logic here
        // ...
  
        // After handling the logic, update the state and make API calls if needed
        // ...
  
        // Example code to update state (replace with your actual logic):
       
        const sourceItems = [...sourceColumn.items];
        const destItems = [...destColumn.items];
  
        const [removed] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, removed);
  
        setColumns({
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems,
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems,
          },
        });
      } else {
        // Handle reordering within the same column (if needed)
        // ...
      }
    };
  
    return (
      <div className="adminview pipeline-view wrapper">
        <AdminHeader />
        <AdminSidebar />
        <div className="content-wrapper px-3 pt-1 pb-3">
            <div className="content-header">
                <div className="row">
                    <div className="col-sm-6 pl-0">
                        <h1 className="m-0">
                            Edit Position Pipeline
                            <small>
                            <NavLink to="/admin/position-pipeline"><i className="fas fa-arrow-circle-left"></i> back to position list</NavLink>
                            </small>
                        </h1>
                    </div>
                    <div className="col-sm-6 pr-0">
                    </div>
                </div>
            </div>
            
            <div className="form-horizontal">

                <div className="card card-secondary card-outline" data-card-name="customer-info" id="customer-info">
                    <div className="card-header with-border clearfix">
                        <div className="card-title d-flex"><h3>{positionName}</h3></div>
                        <div className="card-tools float-right">
                            <button className="btn btn-tool" data-card-widget="collapse" type="button">
                                <i className="fa toggle-icon fa-minus"></i>
                            </button>
                        </div>
                    </div>
                    <form onSubmit="" id="edit_user">
                    <input   type="hidden" value=""/>
                    <div className="card-body">
                        <div className="col-sm-12 row">
                        </div>   
                        <div className="form-horizontal card mt-2">
                            <div className="card-body overflow-auto">
                            {columns ? (
                                <div
                                style={{ display: "flex", justifyContent: "start", height: "100%", gap: 10 }}
                                >
                                <DragDropContext onDragEnd={onDragEnd}>
                                    {Object.entries(columns).map(([statusName, column], index) => (
                                    <div
                                        className="pipeline-item col-sm-6"
                                        style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "start",
                                        border: "1px solid #edeff0",
                                        }}
                                        key={statusName}
                                    >
                                        <h2>
                                        <span className="stage-icon">
                                            <i className="far fa-user"></i>
                                        </span>
                                        {column.position_name}
                                        <span className="candidates-total">{column.items.length}</span>
                                        </h2>
                                        <div class="col-sm-12" style={{ margin: 0 }}>
                                        <Droppable droppableId={statusName} key={statusName}>
                                            {(provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={{
                                                background: snapshot.isDraggingOver ? "#fff" : "#fff",
                                                padding: "0px 8px 0px 8px",
                                                minHeight: 500,
                                                }}
                                                class="col-sm-12"
                                            >
                                                {column.items.map((item, index) => (
                                                <Draggable
                                                    key={item.id}
                                                    draggableId={item.id.toString()}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                    <div
                                                        className="card small still "
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                        userSelect: "none",
                                                        padding: 0,
                                                        margin: "0 0 8px 0",
                                                        minHeight: "50px",
                                                        backgroundColor: snapshot.isDragging
                                                            ? "#fff"
                                                            : "#fff",
                                                        color: "#292d32",
                                                        ...provided.draggableProps.style,
                                                        }}
                                                    >
                                                        <div className="card-box candidate">
                                                        {/* Render your card content here using 'item' */}
                                                        <div className="candidate-container">
                                                            <div className="candidate-color-bar-container">
                                                            <div person="candidate" className="ng-isolate-scope sm avatar color4 good" initial={Array.from(item.status_name)[0]}></div>
                                                            </div>
                                                            <div person="candidate"></div>
                                                            <div className="candidate-info">
                                                                <div className="candidate-header"><h3><span>{item.status_name}</span></h3></div>
                                                                <div className="candidate-body">
                                                                    <p className="headline">
                                                                        {/* <span><i className="fa-solid fa-clock"></i> {item.days_difference}</span>     */}
                                                                            <span className="float-right">
                                                                                <NavLink to={'/admin/edit-application/'+item.id}><i className="fas fa-edit" style={{fontSize:15}}></i></NavLink>
                                                                        </span> 
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    )}
                                                </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                            )}
                                        </Droppable>
                                        </div>
                                    </div>
                                    ))}
                                </DragDropContext>
                                </div>
                            ) : (
                                <LoadingIcon />
                            )}
                            </div>
                        </div>                     
                    </div>
                    </form>
                </div>
            </div>
        </div>
        <AdminFooter />
      </div>
    );
  };
  
  export default ApplicationsPipeline;
