import React,{useState,useEffect} from 'react';
import {NavLink,useNavigate,useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useAppState } from "../hooks/state";
import common from '../services/common';
const EmploymentApplicationStep1 = () => {
    const today = new Date();
    const year = today.getFullYear();
    const navigate = useNavigate();
    const [state, setState] = useAppState();

    const [dynamicField, setDynamicField] = useAppState();



    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
      } = useForm({
        defaultValues: state,
      });

      const onSubmit = (data) => {
        //console.log(data);
        setState({ ...state, ...data });
        navigate("/employment/position");
        
    
       }
       const location = useLocation();

       useEffect(() => {


         

        (async () => {
            var dropdownvalue= await common.getdropdown(null);           

            setDynamicField(dropdownvalue.data.data)
            
            
          })();
    
      }, []);
        
  return (
        <div className="web-version">
            
            <div className="header-main">
              <div className="header-top">
                  <div className="container">
                  <nav className="navbar navbar-expand-sm navbar-dark">
                      <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/user/login">Login</NavLink>
                        </li>
                        <li className="nav-item">
                            <span className="nav-link"><small>|</small></span>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link pr-0" to="/">Register</NavLink>
                        </li>
                      </ul>
                      </nav>
                  </div>
              </div>
              <div className="header-middle">
                  <div className="container">
                  <div className="d-flex align-items-center">
                    <div className="mr-auto">
                      <NavLink className="brand-logo-link" to="/">
                        <img src="../Poss logo-01.png?v=1" alt="Logo" className="brand-logo" />
                      </NavLink>
                    </div>
                    <div className="ml-auto">
                        <div className="web-header-title">EMPLOYMENT APPLICATION</div>
                    </div>
                    </div>
                  </div>
              </div>
            </div>

            <div className="web-page-content">
                <div className="container">

                 
                {location?.state?.success==1 && <div className="alert alert-success fade in alert-dismissible top-alert"   >
     
                You have successfully registered.we will review your application and get back to you.
</div>} 


                    <div className="web-page-inner">
  <form onSubmit={handleSubmit(onSubmit)}>

                  <div className="steps-content step-content-1">
                    <ul className="progressbar">  
                        <li className="steps step-1 active"><span>1</span><strong>General <br/>Information</strong></li>  
                        <li className="steps step-2"><span>2</span><strong>Position </strong></li>  
                        <li className="steps step-3"><span>3</span><strong>Education and <br/>Training</strong></li>  
                        <li className="steps step-4"><span>4</span><strong>Background</strong></li>
                        <li className="steps step-5"><span>5</span><strong>Professional <br/>References</strong></li> 
                        <li className="steps step-6"><span>6</span><strong>Applicant's Certification <br/>Agreement</strong></li> 
                    </ul>  
                    
                    
                    <div className="card card-primary">
                    <div className="card-header with-border clearfix">
                        <div className="card-title">General Information</div>
                    </div>
                    <div className="card-body">
                        <p>Thank you for your interest in joining our team at Progressive Option Support Services. Please review and complete this employment application in its entirety. </p>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    
                                    <label>First Name <span className="required">*</span></label>
                                    <input className="form-control text-box single-line" name="first_name" type="text" {...register("first_name", { required: "Please enter first name." })} />
                                     
             
 
  {errors.first_name?.message && (
     <p className='text-danger'>{errors.first_name.message}</p>
  )}






                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Last Name <span className="required">*</span></label>
                                    <input className="form-control text-box single-line" name="last_name" type="text"   {...register("last_name", { required: "Please enter last name."})} />
                                 
  

{errors.last_name?.message && (
     <p className='text-danger'>{errors.last_name.message}</p>
  )}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Email <span className="required">*</span></label>
                                    <input className="form-control text-box single-line" name="email"   type="text" 
                                      {...register("email", {
                                        required: "Please enter email address.",
                                        validate: {
                                          maxLength: (v) =>
                                            v.length <= 50 || "The email should have at most 50 characters",
                                          matchPattern: (v) =>
                                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                            "Please enter a valid email address",
                                        },
                                      })}
                                    />
                                                                    
      {errors.email?.message && (
     <p className='text-danger'>{errors.email.message}</p>
  )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Street Address <span className="required">*</span></label>
                                    <input className="form-control text-box single-line" name="address"   type="text"  {...register("address", { required: "Please enter street address."})}/>


                                    {errors.address?.message && (
     <p className='text-danger'>{errors.address.message}</p>
  )}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Street Address Line 2  </label>
                                    <input className="form-control text-box single-line" name="address_2"   type="text"  {...register("address_2")}/>

                                    {errors.address_2?.message && (
     <p className='text-danger'>{errors.address_2.message}</p>
  )}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>City <span className="required">*</span></label>
                                    <input className="form-control text-box single-line" name="city"   type="text" {...register("city", { required: "Please enter city."})} />
                                    {errors.city?.message && (
     <p className='text-danger'>{errors.city.message}</p>
  )}
                                </div>
                            </div>
                        </div>                        
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>State / Province <span className="required">*</span></label>
                                    <input className="form-control text-box single-line" name="state"   type="text" {...register("state", { required: "Please enter state."})} />
                                    {errors.state?.message && (
     <p className='text-danger'>{errors.state.message}</p>
  )}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Postal / Zip Code <span className="required">*</span></label>
                                    <input className="form-control text-box single-line" name="zipcode"   type="text"
                                    
                                    
                                    {...register("zipcode", {
                                        required: {
                                          value: true,
                                          message: "Please enter zip code."
                                        }, 
                                        pattern: {
                                          value: /^[0-9+-]+$/,
                                          message: "This is not a valid zip code."
                                        }, 
                                        minLength: {
                                          value: 5,
                                          message: "Please enter valid zip code."
                                        }, 
                                        maxLength: {
                                          value: 5,
                                          message: "Please enter valid zip code."
                                        } })}
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                     />


                                    {errors.zipcode?.message && (
     <p className='text-danger'>{errors.zipcode.message}</p>
  )}

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Cell Phone Number <span className="required">*</span></label>
                                    <input className="form-control text-box single-line" name="phone"   type="text" 
                                    
                                    {...register("phone", {
                                        required: {
                                          value: true,
                                          message: "Please enter cell phone number."
                                        }, 
                                        pattern: {
                                          value: /^[0-9+-]+$/,
                                          message: "This is not a valid cell phone number."
                                        }, 
                                        minLength: {
                                          value: 10,
                                          message: "Please enter valid cell phone number."
                                        }, 
                                        maxLength: {
                                          value: 12,
                                          message: "Please enter valid cell phone number."
                                        } })}
                                    
                                    
                                    />

                                    {errors.phone?.message && (
     <p className='text-danger'>{errors.phone.message}</p>
  )}

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Are you legally entitled to work in the U.S.? <span className="required">*</span></label>
                                    <select className="form-control" name="legally_entitled_us"  {...register("legally_entitled_us", { required: "Please select legally entitled."})}>
                                        <option value="">Please Select</option>
                                        <option value={1}>Yes</option>
                                        <option value={0}>No</option>
                                    </select>

                                    {errors.legally_entitled_us?.message && (
     <p className='text-danger'>{errors.legally_entitled_us.message}</p>
  )}
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                  <div className="card-footer-step text-center">
                      {/*
                      <NavLink className="btn btn-primary" to="/employment-application/step-2">Save & Next <i className="right fas fa-angle-right"></i></NavLink>
                      */}

                      <button type="submit" className="btn btn-primary">Save & Next<i className="right fas fa-angle-right"></i></button>
                  </div>
                </div>
                </form>



                
                </div>
              </div>
            </div>


            <div className="footer-main bg-dark">
                <div className="container">
                    <p className="text-center">Copyrights © {year} All rights reserved. POSS</p>
                </div>
            </div>

        </div>
    );
};

export default EmploymentApplicationStep1;
