
import { NavLink, useNavigate,useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";

import AdminHeader from '../layout/AdminHeader';
import AdminSidebar from '../layout/AdminSidebar';
import AdminFooter from '../layout/AdminFooter';
import Candidates from "../component/Candidates";
import Config from "../config/config";
const ApplicationsList = () => {

  let { id, locationid } = useParams();

  const [heading, setHeading] = useState();

  var user_data = localStorage.getItem("user_data");
  var user_data_json = JSON.parse(user_data);
  const navigate = useNavigate();
	
  const headingTitle = (newHeading) => {
    setHeading(newHeading);
  }
  useEffect(() => {

    if(!['1','2','3'].includes(user_data_json.role_id))
    {
      navigate('/user/login');
       return;
    }

}, [])

  return (
    
        <div className="adminview pipeline-view wrapper">
          <AdminHeader />
          <AdminSidebar />
          
          <div className="content-wrapper px-3 pt-1 pb-3">
            <div className="content-header">
                <div className="row">
                    <div className="col-sm-6 pl-0">
                        <h1 className="m-0">{heading}</h1>
                    </div>
                    <div className="col-sm-6 pr-0">
                        <div className="float-right">
                        <ul className="head-tabs">              
                           <li className="tab-item">
                                <NavLink className="tab-item-link" to={'/admin/applications-pipeline/'+id+'/'+locationid}>  Pipeline</NavLink>
                           </li>
                           <li className="tab-item">
                                <NavLink className="tab-item-link active" to={'/admin/applications-list/'+id}>Candidates</NavLink>
                           </li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mt-2">
                    <div className="card-body">
                        <div className="table-responsive">

                        <Candidates headingTitle={headingTitle}></Candidates>



                         
                        </div>
                    </div>
                </div>
        </div>

        <AdminFooter />
       </div>
      
    );
};



export default ApplicationsList;
