import React,{useState,useEffect} from 'react';
 
import { useForm,Controller } from "react-hook-form";
import { useNavigate, Link,NavLink } from "react-router-dom";
import { useAppState } from "../hooks/state";

 

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import common from '../services/common';

const EmploymentApplicationStep2 = () => {

    const today = new Date();
    const year = today.getFullYear();
    const navigate = useNavigate();
    const [state, setState] = useAppState();
   


    const {
        control,
        register,
        handleSubmit,
        formState: { errors }
      } = useForm({
        defaultValues: state,
      });
     
      const [dynamicField, setDynamicField] = useAppState();


      const onSubmit = (data) => {
        //console.log(data);
        setState({ ...state, ...data });
        navigate("/employment/education");
        
    }

    useEffect(() => {
        
 
        console.log(state)


if (Object.keys(dynamicField).length == 0) {
  
    // navigate("/");
        
 }



    }, []);
    
  return (
        <div className="web-version">
            
            <div className="header-main">
              <div className="header-top">
                  <div className="container">
                  <nav className="navbar navbar-expand-sm navbar-dark">
                      <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/user/login">Login</NavLink>
                        </li>
                        <li className="nav-item">
                            <span className="nav-link"><small>|</small></span>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link pr-0" to="/">Register</NavLink>
                        </li>
                      </ul>
                      </nav>
                  </div>
              </div>
              <div className="header-middle">
                  <div className="container">
                  <div className="d-flex align-items-center">
                    <div className="mr-auto">
                      <NavLink className="brand-logo-link" to="/">
                        <img src="../Poss logo-01.png?v=1" alt="Logo" className="brand-logo" />
                      </NavLink>
                    </div>
                    <div className="ml-auto">
                        <div className="web-header-title">EMPLOYMENT APPLICATION</div>
                    </div>
                    </div>
                  </div>
              </div>
            </div>

            <div className="web-page-content">
                <div className="container">
                    <div className="web-page-inner">
                    <form onSubmit={handleSubmit(onSubmit)}>
                <div className="steps-content step-content-2">
                    <ul className="progressbar">  
                        <li className="steps step-1 active"><span>1</span><strong>General <br/>Information</strong></li>  
                        <li className="steps step-2 active"><span>2</span><strong>Position </strong></li>  
                        <li className="steps step-3"><span>3</span><strong>Education and <br/>Training</strong></li>  
                        <li className="steps step-4"><span>4</span><strong>Background</strong></li>
                        <li className="steps step-5"><span>5</span><strong>Professional <br/>References</strong></li> 
                        <li className="steps step-6"><span>6</span><strong>Applicant's Certification <br/>Agreement</strong></li> 
                    </ul>  
                   
                    
                    <div className="card card-primary">
                    <div className="card-header with-border clearfix">
                        <div className="card-title">Position</div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                        <div className="col-md-12">
                                <div className="form-group">
                                    {/* <label>Employment Type <span className="required">*</span></label> */}
                                    <div className="raw">

                                    


                                    {/* {dynamicField.employement_type_field && dynamicField.employement_type_field.map((et, key) => {
                                          return <div className="form-check-inline" key={key}>
                                          <label className="form-check-label"> <input type="radio" name="employment_type_id" className="form-check-input" value={et.id}       {...register('employment_type_id', { required: "Please select employment type." })}/>
                                          {et.name}</label>
                                      </div>;
                                      })}  */}


                                        
                                       


                                    </div>

                                    {errors.employment_type_id?.message && (
     <p className='text-danger'>{errors.employment_type_id.message}</p>
  )}
                                </div>
                          </div>
                          <div className="col-md-12">
                                <div className="form-group">
                                    <label>Position or Type of Employment Desired <span className="required">*</span></label>
                                    <div className="">


                                    {dynamicField.position_field &&  dynamicField.position_field.map((po, key) => {
        return <div className="form-check" key={key}>
       <label className="form-check-label"> <input type="checkbox" name="position_id" className="form-check-input"  value={po.id} {...register('position_id', { required: "Please select position or type of employment." })}/>
        {po.name} -{po.area_name}</label>
    </div>;
    })} 
        </div>

                                    {errors.position_id?.message && (
     <p className='text-danger'>{errors.position_id.message}</p>
  )}
                                </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                <label>Interested In<span className="required">*</span></label>
                                <div className="raw">


                                {dynamicField.interested_in_field && dynamicField.interested_in_field.map((ii, key) => {
                                  return  <div className="form-check" key={key}>
                                       <label className="form-check-label"> <input type="checkbox" name="desired_type" className="form-check-input" value={ii.id} {...register('desired_type', { required: "Please select interested in." })}/>
                                        {ii.name}</label>
                                    </div>;

})} 
                                    
                                </div>
                                {errors.desired_type?.message && (
     <p className='text-danger'>{errors.desired_type.message}</p>
  )}
                                
                            </div>


                            
                          </div>
                          {/* <div className='col-md-6'>
                          <div className="form-group">
                            <label>Preferred Areas <span className="required">*</span></label>
                            <div className="raw">

                                  {dynamicField.preferred_area_field &&  dynamicField.preferred_area_field.map((pa, key) => {
                                    return <div className="form-check" key={key}>
                                      <label className="form-check-label"> <input type="checkbox" name="area_type" className="form-check-input"  value={pa.id} {...register('area_type', { required: "Please select preferred area." })}/>
                                        {pa.name}</label>
                                    </div>;

                                      })} 
                                  
                                </div>


                                {errors.area_type?.message && (
                                      <p className='text-danger'>{errors.area_type.message}</p>
                                    )}


                            </div>
                        </div> */}
                        </div>
                        
                        <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                    <label>Are you able to perform the essential functions of the job you are applying for, with or without reasonable accommodation? <span className="required">*</span></label>
                                    <div className="raw">
                                        <div className="form-check-inline">
                                        <label className="form-check-label" htmlFor="accommodation_Yes"> <input type="radio" className="form-check-input" name="perform_the_essential_functions" id="accommodation_Yes"       value={1} {...register('perform_the_essential_functions', { required: "Please select perform the essential functions of the job." })}/>
                                            Yes</label>
                                        </div>
                                        <div className="form-check-inline">
                                        <label className="form-check-label" htmlFor="accommodation_No"> <input type="radio" className="form-check-input" name="perform_the_essential_functions" id="accommodation_No"   value={0} {...register('perform_the_essential_functions', { required: "Please select perform the essential functions of the job." })}/>
                                            No</label>
                                        </div>
                                    </div>
                                    {errors.perform_the_essential_functions?.message && (
     <p className='text-danger'>{errors.perform_the_essential_functions.message}</p>
  )}

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Date Available <span className="required">*</span></label>

                                    
                                    <div className="raw">

                                    <Controller
    control={control}
    name='date_available'
    rules={{ required: "Please select available date" }}
    render={({ field }) => (
      <DatePicker
      className="form-control text-box single-line"  
        placeholderText='Select date'
        onChange={(date) => field.onChange(date)}
        selected={field.value}
        minDate={new Date()}
        dateFormat="MM/dd/yyyy"
      
      />
 

 
   )}
  />

</div>
            {errors.date_available?.message && (
     <p className='text-danger'>{errors.date_available.message}</p>
  )}
  
  
  
                       </div>
                            </div>
                        </div>
                    </div>
                    </div>
                  <div className="card-footer-step text-center">
                        <NavLink className="btn btn-primary" to="/"><i className="right fas fa-angle-left"></i> Previous</NavLink>

                        
                      
                       {/*
                        <NavLink className="btn btn-primary" to="/employment-application/step-3">Next <i className="right fas fa-angle-right"></i></NavLink>
                        */}

<button type="submit" className="btn btn-primary">Save & Next<i className="right fas fa-angle-right"></i></button>
                  </div>

                  
                </div>
                </form>
                
                </div>
              </div>
            </div>


            <div className="footer-main bg-dark">
                <div className="container">
                    <p className="text-center">Copyrights © {year} All rights reserved. POSS</p>
                </div>
            </div>

        </div>
    );
};

export default EmploymentApplicationStep2;
