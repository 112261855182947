import React from 'react';
const AdminFooter = () => {
  const today = new Date();
  const year = today.getFullYear();
  return(

    <footer className="main-footer">
            <strong>Copyright &copy; {year} <a target="_blank" href="#">POSS</a>.</strong> All rights reserved.
    </footer>

  );
};

export default AdminFooter;
