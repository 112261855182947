import React,{useState,useEffect} from 'react';
import {NavLink,useNavigate,useLocation,useParams   } from "react-router-dom";
import { useForm ,Controller} from 'react-hook-form';
import AdminHeader from '../layout/AdminHeader';
import AdminSidebar from '../layout/AdminSidebar';
import AdminFooter from '../layout/AdminFooter';
import common from '../services/common'; 
import { Store } from 'react-notifications-component';
import Config from '../config/config';
const EditProfile = () => {

    const [apiEdit, setApiEdit] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const {
        control,
        register,
        getValues,
        handleSubmit,
        setValue,
        reset,
        formState: { errors }
      } = useForm({
        defaultValues: {apiEdit},
      });
     

      const onSubmit = async (data) => {
        setLoading(true); 

    

  

        var UpdateProfileapi=   await common.updateprofile(data);  


           if(UpdateProfileapi?.data?.status==400)
        {
          navigate('/user/logout');
          return;
        }

     
       if(UpdateProfileapi?.data?.status==202)
       {
         Store.addNotification({
            title: "Success!",
            message: UpdateProfileapi?.data?.message,
            type: "success",           
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          //navigate('/admin/portal-users',{state:{success:'1'}});
       
         setLoading(false);
       }
       else
       {

       // alert(UpdatePortalUserapi?.response?.message);

  


         setLoading(false);
     
         Store.addNotification({
            title: "Error!",
            message: UpdateProfileapi?.data?.message,
            type: "danger",            
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
         
        
        
    
      }
    }
    useEffect(() => {

        clearInterval(Config.intervalId);


        (async () => {

            var user_data=localStorage.getItem("user_data");     
      var user_data_json=JSON.parse(user_data);    


     
       var  edituser= await common.edituser(user_data_json.user_id);  


       if(edituser?.data?.status==400)
       {
         navigate('/user/logout');
         return;
       }


      var dataapi= {first_name:edituser.data.data?.first_name,last_name:edituser.data.data?.last_name,phone:edituser.data.data?.phone,email:edituser.data.data?.email}
       setApiEdit(dataapi);
       reset(dataapi);
    })();
    }, [reset]);
  return (
    
        <div className="adminview wrapper">
          <AdminHeader />
          <AdminSidebar />
          
          <div className="content-wrapper px-3 pt-1 pb-3">
            <div className="content-header">
                <div className="row">
                    <div className="col-sm-6 pl-0">
                        <h1 className="m-0">
                            Edit Profile
                        </h1>
                    </div>
                    <div className="col-sm-6 pr-0">
                        <div className="float-right">
                            <button type="submit" className="btn btn-primary" form='edit_user'>
                                <i className="far fa-save"></i> Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="form-horizontal">

                <div className="card card-secondary card-outline" data-card-name="customer-info" id="customer-info">
                    <div className="card-header with-border clearfix">
                        <div className="card-title"><i className="fas fa-info"></i>User info</div>
                        <div className="card-tools float-right">
                            <button className="btn btn-tool" data-card-widget="collapse" type="button">
                                <i className="fa toggle-icon fa-minus"></i>
                            </button>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} id="edit_user">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <div className="col-md-3">
                                        <div className="label-wrapper">
                                            <label className="col-form-label">First Name <span className="required">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <input className="form-control text-box single-line" type="text" {...register("first_name", { required: "Please enter first name." })} />
                                        {errors.first_name?.message && (
     <p className='text-danger'>{errors.first_name.message}</p>
  )}

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <div className="col-md-3">
                                        <div className="label-wrapper">
                                            <label className="col-form-label">Last Name <span className="required">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <input className="form-control text-box single-line" type="text"  {...register("last_name", { required: "Please enter last name."})}/>
                                        {errors.last_name?.message && (
     <p className='text-danger'>{errors.last_name.message}</p>
  )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <div className="col-md-3">
                                        <div className="label-wrapper">
                                            <label className="col-form-label">Phone <span className="required">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <input className="form-control text-box single-line" type="text"   {...register("phone", {
                                        required: {
                                          value: true,
                                          message: "Please enter cell phone number."
                                        }, 
                                        pattern: {
                                          value: /^[0-9+-]+$/,
                                          message: "This is not a valid cell phone number."
                                        }, 
                                        minLength: {
                                          value: 10,
                                          message: "Please enter valid cell phone number."
                                        }, 
                                        maxLength: {
                                          value: 12,
                                          message: "Please enter valid cell phone number."
                                        } })} />
                                        
                                    {errors.phone?.message && (
     <p className='text-danger'>{errors.phone.message}</p>
  )}

                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-md-6">
                                <div className="form-group row">
                            <div className="col-md-3">
                                <div className="label-wrapper">
                                    <label className="col-form-label">Email <span className="required">*</span></label>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <input className="form-control text-box single-line" type="text" defaultValue={apiEdit?.email}  disabled />
                                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group row">
                            <div className="col-md-3">
                                <div className="label-wrapper">
                                    <label className="col-form-label">Password <span className="required">*</span></label>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="input-group">
                                    <input className="form-control text-box single-line password" type="password"   {...register("password")}  />
                                     
                                </div>
                            </div>
                        </div>
                    </div>
                   
                        </div>

                        
                        
                    </div>
                    </form>
                </div>
            </div>


        </div>

        <AdminFooter />
       </div>
      
    );
};

export default EditProfile;
