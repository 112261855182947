import React,{useState,useEffect } from 'react';
import {NavLink,useNavigate,useLocation,useParams   } from "react-router-dom";
import { useForm ,Controller} from 'react-hook-form';
import AdminHeader from '../layout/AdminHeader';
import AdminSidebar from '../layout/AdminSidebar';
import AdminFooter from '../layout/AdminFooter';
import common from '../services/common';
import Select from 'react-select';
import { Store } from 'react-notifications-component';
import Config from '../config/config';
import { Button,Modal } from 'react-bootstrap'

 

const EditPortalUser = () => {

    const [dropdownData, setDropdownData] = useState(null);
  
    const [show, setShow] = useState(false);
    const [userrole, setUserrole] = useState(null);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var user_data = localStorage.getItem("user_data");
    var user_data_json = JSON.parse(user_data);

    let { id } = useParams();

    // const [passwordEnabled, setPasswordEnabled] = useState(false);
    // const [password, setPassword] = useState('');
   
    
    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState();
    const [dropDownOpt, setdropDownOpt] = useState([]);  
    const [apiEdit, setApiEdit] = useState([])
    const navigate = useNavigate();

    // const PasswordInput = ({ name, label, value, onChange, error }) => {};
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // const {
    //     control,
    //     register,
    //     getValues,
    //     handleSubmit,
    //     setValue,
    //     reset,
    //     formState: { errors }
    //   } = useForm({
    //     defaultValues: {apiEdit},
    //   });

      const { control, register, handleSubmit, setValue, reset, watch, formState: { errors } } =  useForm({
        defaultValues: {apiEdit},
      });
      const passwordEnabled = watch('allow_direct_deposit');

   

      


      
      const onSubmit = async (data) => {
        setLoading(true); 

    
    let position_id_m = data.position_id.map(a => a.value);
        data.position_id=position_id_m;
        

        user_data_json.deposit_password = data.deposit_password; // Replace 'yourKeyToUpdate' and "new value" with the actual key and value you want to update.
        const updated_user_data = JSON.stringify(user_data_json);
        localStorage.setItem("user_data", updated_user_data);

        var UpdatePortalUserapi=   await common.AddorupdatePortalUser(data);  

        if(UpdatePortalUserapi?.data?.status==400)
        {
          navigate('/user/logout');
          return;
        }

     
       if(UpdatePortalUserapi?.data?.status==202)
       {
         Store.addNotification({
            title: "Success!",
            message: UpdatePortalUserapi?.data?.message,
            type: "success",           
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          navigate('/admin/portal-users',{state:{success:'1'}});
       
         setLoading(false);
       }
       else
       {

       // alert(UpdatePortalUserapi?.response?.message);

  


         setLoading(false);
     
         Store.addNotification({
            title: "Error!",
            message: UpdatePortalUserapi?.data?.message,
            type: "danger",            
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
         
        
        
    
      }
    }



    const deleteUser = async () => {
        setLoading(true); 

        var deleteid = new FormData();
        deleteid.append('user_id', id);

       // $("#delete-confirmation").modal("hide");
    
        var UpdatePortalUserapi=   await common.deleteuser(deleteid);  
     
       if(UpdatePortalUserapi?.data?.status==202)
       {
         Store.addNotification({
            title: "Success!",
            message: UpdatePortalUserapi?.data?.message,
            type: "success",           
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          navigate('/admin/portal-users',{state:{success:'1'}});
       
         setLoading(false);
       }
       else
       {

   

  


         setLoading(false);
     
         Store.addNotification({
            title: "Error!",
            message: UpdatePortalUserapi?.data?.message,
            type: "danger",            
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
         
        
        
    
      }
    }


    useEffect(() => {
          
    
        

        (async () => {
            clearInterval(Config.intervalId);

            if(!['1'].includes(user_data_json.role_id))
            {
              navigate('/user/login');
               return;
            }

            var dropdownvalue= await common.getdropdown(null);     
            
           

            setDropdownData(dropdownvalue.data.data)

        
            const dropDownValuedata = dropdownvalue.data.data.position_field_2.map((response) => ({
                "value" : response.id,
                "label" : response.name
              }))

              setdropDownOpt(dropDownValuedata);



              var  edituser= await common.edituser(id);  

              
              setTimeout(() => {

                console.log(edituser.data);

               
                var dropDownValuedataedit =  dropDownValuedata.filter(function(po) {

               

                    const position_ids = edituser.data.data.position_id.map(str => {
                        return parseInt(str, 10);
                      });
                    
//alert(position_ids.includes(po.value));

                    return  position_ids.includes(po.value);

                    
                  });

                   

                 
                   

                  edituser.data.data.position_id=dropDownValuedataedit;
               
                setApiEdit(edituser.data.data);
               reset(edituser.data.data);
 
                
               setUserrole(edituser.data.data.role_id)


              }, 0);


       
            
 
           
            
          })();
    
      }, [reset]);
  return (
    
        <div className="adminview wrapper">
          <AdminHeader />
          <AdminSidebar />
          
          <div className="content-wrapper px-3 pt-1 pb-3">
            <div className="content-header">
                <div className="row">
                    <div className="col-sm-6 pl-0">
                        <h1 className="m-0">
                            Edit Portal User
                            <small>
                            <NavLink to="/admin/portal-users"><i className="fas fa-arrow-circle-left"></i> back to portal user list</NavLink>
                            </small>
                        </h1>
                    </div>
                    <div className="col-sm-6 pr-0">
                        <div className="float-right">
                            <button type="submit" className="btn btn-primary" form='edit_user'>
                                <i className="far fa-save"></i> Save
                            </button>
                            <span className="btn btn-danger" onClick={handleShow}>
                            <i className="far fa-trash-alt"></i> Delete</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="form-horizontal">

                <div className="card card-secondary card-outline" data-card-name="customer-info" id="customer-info">
                    <div className="card-header with-border clearfix">
                        <div className="card-title"><i className="fas fa-info"></i>Portal User info</div>
                        <div className="card-tools float-right">
                            <button className="btn btn-tool" data-card-widget="collapse" type="button">
                                <i className="fa toggle-icon fa-minus"></i>
                            </button>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} id="edit_user">
                    <input   type="hidden"  {...register("user_id" )} value={id}/>
                    <div className="card-body">
                        <div className="row">

                      
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <div className="col-md-3">
                                        <div className="label-wrapper">
                                            <label className="col-form-label">First Name <span className="required">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <input className="form-control text-box single-line" type="text"  {...register("first_name", { required: "Please enter first name." })} />
                                        {errors.first_name?.message && (
     <p className='text-danger'>{errors.first_name.message}</p>
  )}

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <div className="col-md-3">
                                        <div className="label-wrapper">
                                            <label className="col-form-label">Last Name <span className="required">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <input className="form-control text-box single-line" type="text"  {...register("last_name", { required: "Please enter last name."})}/>
                                        {errors.last_name?.message && (
     <p className='text-danger'>{errors.last_name.message}</p>
  )}

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <div className="col-md-3">
                                        <div className="label-wrapper">
                                            <label className="col-form-label">Phone <span className="required">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <input className="form-control text-box single-line" type="text" {...register("phone", { required: "Please enter cell phone number."})}/>
                                        {errors.phone?.message && (
     <p className='text-danger'>{errors.phone.message}</p>
  )}

                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-md-6">
                                <div className="form-group row">
                            <div className="col-md-3">
                                <div className="label-wrapper">
                                    <label className="col-form-label">Email <span className="required">*</span></label>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <input className="form-control text-box single-line" type="text"  {...register("email", {
                                                    required: "Please enter email address.",
                                                    validate: {
                                                      maxLength: (v) =>
                                                        v.length <= 50 || "The email should have at most 50 characters",
                                                      matchPattern: (v) =>
                                                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                                        "Please enter a valid email address",
                                                    },
                                                  })} />
                                                  {errors.email?.message && (
     <p className='text-danger'>{errors.email.message}</p>
     )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group row">
                            <div className="col-md-3">
                                <div className="label-wrapper">
                                    <label className="col-form-label">Password <span className="required">*</span></label>
                                </div>
                            </div>
                            <div className="col-md-9">
                                 
                                    <input className="form-control text-box single-line password" type="password"  {...register("password" )}/>
                                    {errors.password?.message && (
     <p className='text-danger'>{errors.password.message}</p>
     )}  
                                 
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group row">
                            <div className="col-md-3">
                                <div className="label-wrapper">
                                    <label className="col-form-label">Portal User Role <span className="required">*</span></label>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <select className="form-control"  onClick={(event) => {
                        // here set target value to state which is 0, 1, 2, 3
                      //  alert(event.target.value);
                        setUserrole(event.target.value);
                    }}   {...register('role_id', { required: "Please select user role." })}>
                                <option value="">Please Select</option>
                                    {dropdownData?.role_field && dropdownData.role_field.filter(pf => pf.id <= 3).map((pf, key) => {
                                      
                                            
                                        return <option value={pf.id}>{pf.name}</option>
                                           

})} 
                                </select>
                                {errors.role_id?.message && (
     <p className='text-danger'>{errors.role_id.message}</p>
     )}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">

                    {userrole!=1 &&  <div className="form-group row">
                            <div className="col-md-3">

                                <div className="label-wrapper">
                                    <label className="col-form-label">Access Position <span className="required">*</span></label>
                                </div>
                            </div>
                            <div className="col-md-9">


                            <Controller
  name="position_id"
  control={control}
  rules={{ required: "Please select access position." }}
  render={({ field }) => (
    <Select
      {...field}
      options={dropDownOpt}
      defaultValue={1}
      isMulti
    />
  )}
/>



                                {errors.position_id?.message && (
     <p className='text-danger'>{errors.position_id.message}</p>
     )}

 
                            </div>
                        </div>}


                            </div>




                    <div className="col-md-6">
                        <div className="form-group row">
                            <div className="col-md-3">
                                <div className="label-wrapper">
                                    <label className="col-form-label">Status <span className="required">*</span></label>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <select className="form-control" {...register('status', { required: "Please select status." })}>
                                <option value="">Please Select</option>
                                    <option value={1}>Enabled</option>
                                    <option value={0}>Disabled</option>
                                </select>
                                {errors.status?.message && (
                                    <p className='text-danger'>{errors.status.message}</p>
                                    )}

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group row">
                            <div className="col-md-3">
                            <div className="label-wrapper">
                                <label className="col-form-label">
                                Show Direct Deposit <span className="required">*</span>
                                </label>
                            </div>
                            </div>
                            <div className="col-md-9">
                            <select
                                className="form-control"
                                {...register('allow_direct_deposit', { required: "Please select Direct Deposit." })}
                            >
                                <option value={0}>Disabled</option>
                                <option value={1}>Enabled</option>
                            </select>
                            {errors.allow_direct_deposit && (
                                <p className='text-danger'>{errors.allow_direct_deposit.message}</p>
                            )}
                            </div>
                        </div>
                        {(passwordEnabled === '1' || apiEdit.allow_direct_deposit == '1')  && (
                            <div className="form-group row">
                                <div className="col-md-3">
                                    <div className="label-wrapper">
                                    <label className="col-form-label">
                                        Security Password <span className="required">*</span>
                                    </label>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="input-group">
                                        <input
                                        type={showPassword ? 'text' : 'password'}
                                        className="form-control"
                                        placeholder="Enter Password"
                                        
                                        {...register('deposit_password', { required: "Password is required" })}
                                        />
                                        <div className="input-group-append">
                                            <button
                                                className="btn btn-outline-secondary"
                                                type="button"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {showPassword ? <i class="nav-icon fas fa-eye-slash" ></i> : <i class="nav-icon fas fa-eye"></i>}
                                            </button>
                                        </div>
                                        {errors.deposit_password && (
                                        <p className='text-danger'>{errors.deposit_password.message}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>


                        </div>

                        
                        
                    </div>
                    </form>
                </div>
            </div>

            
            <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>   <p>Are you sure you want to delete this portal user?</p></Modal.Body>
        <Modal.Footer>
        <button type="submit" className="btn btn-danger" onClick={deleteUser}>Delete</button>
        <span className="btn btn-default" onClick={handleClose}>Cancel</span>
         
        </Modal.Footer>
      </Modal>

        </div>

        <AdminFooter />
       </div>
      
    );
};

export default EditPortalUser;
