
import React, { useEffect, useRef, useState } from "react"
 

const LoadingIcon = () => {

    const loaderStyle = { width: "4rem", height: "4rem" }
    
    return (<div className="d-flex justify-content-center">
    <div className="spinner-border" style={loaderStyle} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>);

}

export default LoadingIcon